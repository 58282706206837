<div class="p-grid dev-env-admin dqp-view">
    <!--    North panel-->
    <div class="p-col-12 dqp-toolbar top-header">
        <div class="dqp-title-icon"><i class="fa fa-globe"></i></div>
        <span class="ig-title">
            <span i18n>Admin</span><i class="fa fa-chevron-right" aria-hidden="true"></i><span i18n>Environments</span>
        </span>
    </div>

    <!--    South panel-->
    <div class="p-grid p-col-12 south-panel">
        <!--
        ----------------------------------------------
        Left panel
        ----------------------------------------------
        -->
        <div class="p-col-3 p-grid left-panel">
            <div class="p-col-12">
                <div class="dqp-toolbar">
                    <ig-search-field class="ig-grow" (textChange)="filterTable($event)"
                                     i18n-placeholder placeholder="Search environments..."></ig-search-field>
                </div>
                <div class="dqp-toolbar ig-padding-m-t">
                    <button igButton icon="fa-plus" i18n-label label="New Environment" (click)="onNewClick($event)"></button>
                    <button igButton icon="fa-refresh" i18n-label label="Refresh" (click)="onRefreshClick($event)"></button>
                </div>
            </div>
            <div class="p-col-12 ig-data-tbl">
                <!-- Popup menu for kebab icon -->
                <p-menu #rowKebabMenu [popup]="true" [model]="rowKebabMenuItems"></p-menu>
                <p-table #table [value]="devEnvs" selectionMode="single" [(selection)]="selectedEnv" dataKey="envId"
                         styleClass="p-datatable-striped"
                         [globalFilterFields]="['name']"
                         [scrollable]="true"
                         >
                    <ng-template pTemplate="header">
                        <tr class="ig-plain">
                            <th [pSortableColumn]="'name'" i18n>Environment Name<p-sortIcon [field]="'name'"></p-sortIcon></th>
                            <th class="col-btn"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr [pSelectableRow]="rowData">
                            <td>{{rowData.name}}</td>
                            <td class="col-btn">
                                <a (click)="onRowKebabClick($event, rowData)"><i class="fa fa-ellipsis-v"></i></a>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <!--
        ----------------------------------------------
        right panel
        ----------------------------------------------
         -->
        <div class="right-panel">
            <div class="p-col-12 dqp-toolbar">
                <span class="selected-env-header">{{selectedEnv?.name}}</span>
                <span class="ig-grow"></span>
                <button [disabled]="!formModel.valid || !isConsideredDirty()" igButton icon="fa-floppy-o"
                        class="ig-button-primary" i18n-label label="Save Changes"
                        (click)="onSaveChangesClick($event)"></button>
            </div>
            <div class="tabViewCt" style="">
                <p-tabView [hidden]="!selectedEnv" [(activeIndex)]="activeTabIndex">
                    <p-tabPanel i18n-header header="Details">
                        <dqp-dev-env-details-tab [selectedDevEnvDetail]="selectedEnvDetail" [devEnvs]="devEnvs"
                                                 (availableEnvGroupsChange)="onAvailableEnvGroupsChange($event)"></dqp-dev-env-details-tab>
                    </p-tabPanel>
                    <p-tabPanel i18n-header header="Roles">
                        <dqp-dev-env-roles-tab [selectedDevEnvDetail]="selectedEnvDetail"></dqp-dev-env-roles-tab>
                    </p-tabPanel>
                    <p-tabPanel i18n-header header="Properties" class="properties-panel">
                        <dqp-dev-env-properties-tab [selectedDevEnvDetail]="selectedEnvDetail"></dqp-dev-env-properties-tab>
                    </p-tabPanel>
                    <p-tabPanel i18n-header header="Execution Profile">
                        <dqp-dev-env-execution-profile-tab [selectedDevEnvDetail]="selectedEnvDetail"></dqp-dev-env-execution-profile-tab>
                    </p-tabPanel>
                    <p-tabPanel i18n-header header="Govern">
                        <dqp-dev-env-govern-tab [selectedDevEnvDetail]="selectedEnvDetail"
                                                (showDifferentTimeZoneWarning)="onShowDifferentTimeZoneWarning($event)"></dqp-dev-env-govern-tab>
                    </p-tabPanel>
                    <p-tabPanel i18n-header header="Spectrum OnDemand">
                        <dqp-dev-env-spectrum-tab [selectedDevEnvDetail]="selectedEnvDetail"></dqp-dev-env-spectrum-tab>
                    </p-tabPanel>
                    <p-tabPanel i18n-header header="Change History">
                        <dqp-dev-env-history-tab [selectedDevEnvDetail]="selectedEnvDetail"
                                                 (historySettingLoadClick)="onHistorySettingLoadClick($event)">
                        </dqp-dev-env-history-tab>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
    </div>
</div>
